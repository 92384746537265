html {
    @apply scroll-auto;
}

.page {
    section:not(:first-of-type) {
        @apply pt-3;
    }

    h1 {
        @apply text-2xl;
    }

    h2 {
        @apply mt-3 text-lg;
    }

    p {
        @apply text-sm;
    }

    dd {
        @apply text-sm;
    }

    ol,
    ul {
        @apply pl-6 text-sm;
    }

    a {
        @apply font-normal;
    }

    table {
        @apply border-t border-b border-grey-300;

        thead {
            @apply border-grey-300;
        }

        tr {
            @apply border-grey-300;
        }

        td,
        th {
            @apply py-3;
        }

        th {
            @apply px-4 font-normal text-grey-600;
        }
    }
}

.transaction {
    table {
        tbody {
            th,
            td {
                @apply align-middle;
            }
        }
    }
}

:global .dark {
    :local .page {
        ol,
        ul {
            @apply text-sm;
        }

        table {
            @apply border-grey-700;

            thead {
                @apply border-grey-700;
            }

            tr {
                @apply border-grey-700;
            }

            th {
                @apply text-grey-400;
            }
        }
    }

    :local .privacyPolicy {
        table {
            @apply border-grey-700;

            thead {
                th {
                    @apply bg-grey-800 text-grey-200;

                    &:not(:last-of-type) {
                        @apply border-grey-700;
                    }
                }
            }

            tbody {
                th {
                    @apply border-grey-700 bg-grey-800 font-semibold text-grey-200;
                }

                td {
                    &:not(:last-child) {
                        @apply border-grey-700;
                    }
                }
            }
        }
    }

    :local .terms {
        li {
            table {
                thead {
                    th {
                        @apply border-grey-700;
                    }
                }
            }
        }
    }
}
