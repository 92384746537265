@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .site-container {
        @apply container mx-auto px-4 sm:px-6 md:px-8 lg:px-12 xl:px-28;
    }

    .border-invalid {
        @apply border-red-600 dark:border-red-400;
    }

    .border-outline {
        @apply border-grey-300 dark:border-grey-700;
    }

    .border-venue-result {
        @apply border border-grey-50 shadow-md dark:border-grey-800;
    }

    .bg-step {
        @apply bg-grey-50 dark:bg-grey-800;
    }

    .bg-body {
        @apply bg-white dark:bg-grey-900;
    }

    .bg-step-dark-only {
        @apply dark:bg-grey-800;
    }

    .text-body {
        @apply text-grey-800 dark:text-white;
    }

    .unselected,
    .text-disabled {
        @apply text-grey-300 dark:text-grey-700;
    }

    .text-golden {
        @apply text-gold-400 dark:text-gold-200;
    }

    .text-invalid {
        @apply text-red-600 dark:text-red-400;

        a:not(.link-item) {
            @apply text-red-600 dark:text-red-400;
        }
    }

    .text-invalid-step {
        @apply text-red-700 dark:text-red-500;
    }

    .shadow-top {
        box-shadow: 0 -4px 6px -1px rgb(0 0 0 / 10%),
            0 -2px 4px -1px rgb(0 0 0 / 6%);
    }
}

html,
body {
    @apply h-full min-h-full;
}

/* The component sent from the backend needs to be styled, otherwise it becomes a bottleneck because of its missing height */
div[id^='Guest-react-component'] {
    @apply h-full min-h-full;
}

html {
    @apply bg-white text-grey-800;
    @apply selection:bg-blue-300 selection:text-white;

    &.dark {
        @apply bg-grey-900 text-white;
    }

    @apply scroll-smooth;

    /* stylelint-disable selector-id-pattern */
    #___content {
        @apply delay-[0ms] duration-[0ms];
        @apply h-full min-h-full;
    }

    /* goes behind the modal to block interactions */
    #cover {
        @apply fixed inset-0 hidden bg-black opacity-0;

        z-index: -15;
    }

    #portal {
        @apply fixed left-0 top-0;
        @apply translate-x-full;
        @apply transition-transform duration-100 ease-out;

        z-index: -10;
    }

    &.portal-open {
        @apply overflow-clip;

        #portal {
            @apply bottom-0 right-0;

            z-index: 10000;
        }
    }

    &.portal-in {
        #portal {
            @apply translate-x-0 delay-100 duration-300;
        }

        @media (max-width: 639px) {
            #___content {
                @apply opacity-0 transition-opacity duration-300;
                @apply pointer-events-none select-none;
            }
        }
    }

    &.portal-modal {
        #portal {
            @apply translate-x-0 transition-none;
        }
    }

    &.portal-visible {
        #___content {
            @apply hidden;
        }
    }

    &.portal-mobile-menu {
        #portal {
            @apply bottom-0 right-0;
            @apply translate-x-0;

            z-index: 10000;
        }
    }

    &.portal-mobile-menu-in {
        #portal {
            @apply translate-x-1/4 delay-100 duration-300 sm:translate-x-1/2;
        }

        #cover {
            @apply block opacity-80;

            z-index: 9500;
        }
    }

    /* Reservation Widget CSS Start */

    #reservation-widget {
        @apply fixed left-0 top-0;
        @apply translate-x-full;
        @apply transition-transform duration-100 ease-out;

        z-index: -10;
    }

    &.reservation-widget-open {
        @apply overflow-clip;

        #reservation-widget {
            @apply bottom-0 right-0;

            z-index: 9000;
        }
    }

    &.reservation-widget-in {
        #reservation-widget {
            @apply translate-x-0 delay-100 duration-300;
        }

        @media (max-width: 639px) {
            #___content {
                @apply opacity-0 transition-opacity duration-300;
                @apply pointer-events-none select-none;
            }
        }
    }

    &.reservation-widget-modal {
        #reservation-widget {
            @apply translate-x-0 transition-none;
        }
    }

    &.reservation-widget-visible {
        #___content {
            @apply hidden;
        }
    }

    /* Reservation Widget CSS End */

    /* Toast Notification CSS Start */

    #toast {
        @apply fixed top-0 flex w-fit transform justify-center md:left-1/2 md:top-28 md:-translate-x-1/2 md:-translate-y-1/2;

        z-index: -10;
    }

    &.toast-open {
        #toast {
            z-index: 90000;
        }
    }

    /* Toast Notification CSS End */

    &.hide-content {
        #___content {
            display: none;
        }
    }
}

a:not(.link-item) {
    @apply text-gold-400 dark:text-gold-200;
}

@layer components {
    input,
    textarea,
    select,
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple] {
        @apply rounded-md border-grey-300 bg-white text-grey-800;
        @apply dark:border-grey-700 dark:bg-grey-900 dark:text-white;
        @apply disabled:text-disabled disabled:cursor-not-allowed;
        @apply invalid:border-red-600 invalid:dark:border-red-400;

        &:focus {
            @apply border-grey-300 outline-none dark:border-grey-700;
        }
    }

    .input-button {
        @apply rounded-md border border-grey-300 bg-white px-3 py-2 text-grey-800;
        @apply dark:border-grey-700 dark:bg-grey-900 dark:text-white;
        @apply focus-visible:ring-1 focus-visible:ring-blue-300 dark:focus-visible:ring-blue-300;
        @apply disabled:text-disabled disabled:cursor-not-allowed;

        &:focus {
            @apply border-grey-300 outline-none dark:border-grey-700;
        }
    }

    .input-wrapper {
        @apply rounded-md border border-grey-300 bg-white;
        @apply dark:border-grey-700 dark:bg-grey-900 dark:text-white;
        @apply focus-within:border-blue-300 focus-within:ring-1 focus-within:ring-blue-300 focus-within:ring-offset-0;
        @apply focus-within:dark:border-blue-300 focus-within:dark:ring-blue-300;

        [type='text'],
        [type='email'],
        [type='url'],
        [type='password'],
        [type='number'],
        [type='date'],
        [type='datetime-local'],
        [type='month'],
        [type='search'],
        [type='tel'],
        [type='time'],
        [type='week'] {
            @apply border-transparent bg-transparent;
            @apply focus:ring-0 focus-visible:ring-0;
        }

        button {
            @apply border-none;
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: #a29a8f;
    }

    .search-input {
        &::placeholder {
            color: #a29a8f;
        }
    }

    .input-invalid {
        @apply border-red-600 bg-red-400/30 dark:border-red-600 dark:bg-red-500/30;
        @apply focus-within:border-red-600 focus-within:ring focus-within:ring-red-100 focus-within:ring-offset-0;

        &::placeholder {
            color: #c85130;
        }

        input::placeholder,
        textarea::placeholder {
            color: #c85130;
        }
    }

    .password-button {
        &:focus {
            @apply border-grey-300 outline-none ring-blue-600 dark:border-grey-700 dark:ring-blue-300;
        }
    }

    .submit-button {
        &:focus {
            @apply outline-none ring-blue-600 dark:ring-blue-300;
        }
    }

    input[type='checkbox'],
    input[type='radio'] {
        @apply cursor-pointer border-grey-300 bg-white;
        @apply dark:border-grey-700 dark:bg-grey-900 dark:text-grey-50;
        @apply disabled:cursor-not-allowed disabled:opacity-50;

        &:checked {
            @apply bg-gold-200;

            &:focus,
            &:hover {
                @apply bg-gold-200;
            }
        }

        &:invalid {
            @apply border-red-600;
            @apply dark:border-red-400;
        }
    }

    hr {
        @apply text-grey-200 dark:text-grey-700;
    }

    select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23c9c3ba' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }
}

.hr-border {
    @apply border-grey-200 dark:border-grey-700;
}

.button-secondary-selected {
    @apply cursor-default border-gold-200 bg-gold-200 text-white dark:text-black;
}

.links-semibold {
    a {
        @apply font-semibold;
    }
}

a.link-body {
    @apply text-body;
}

.icon,
.text-sub {
    @apply text-grey-700 dark:text-grey-300;
}

.bg-outline {
    @apply bg-grey-300 dark:bg-grey-700;
}

.focus-ring {
    @apply focus-visible:ring-1 dark:focus-visible:ring-blue-300;
}

.split-view {
    @apply w-full md:max-w-screen-lg;
    @apply mx-auto lg:container md:px-6 lg:px-12 xl:px-28;
    @apply grid grid-cols-1 gap-5 md:grid-cols-12;

    .split-view-wide {
        @apply md:col-span-7 lg:col-span-8;
    }

    .split-view-narrow {
        @apply hidden md:col-span-5 md:block lg:col-span-4;
    }
}

.prose {
    a {
        @apply hover:underline;
    }

    .links-semibold {
        a {
            @apply font-semibold;
        }
    }

    ul > li::marker,
    ol > li::marker {
        @apply text-grey-900 dark:text-white;
    }
}

.read-more-gradient {
    background: linear-gradient(
        180deg,
        rgb(255 255 255 / 0%) 0%,
        rgb(255 255 255 / 100%) 100%
    );
}

.skeleton {
    @apply pointer-events-none select-none;

    animation: skeleton-pulse 1.75s infinite;

    --skeleton-0: #efedea;
    --skeleton-50: #d7d2cb;
    --skeleton-100: #efedea;

    &.step {
        --skeleton-0: #efedea;
        --skeleton-50: #d7d2cb;
        --skeleton-100: #efedea;
    }

    @keyframes skeleton-pulse {
        0% {
            background: var(--skeleton-0);
        }

        50% {
            background: var(--skeleton-50);
        }

        100% {
            background: var(--skeleton-100);
        }
    }

    li {
        @apply w-fit rounded text-transparent;

        animation: skeleton-pulse 1.75s infinite;
    }
}

ul.skeleton {
    animation: none;
}

.dark {
    .ring-1 {
        box-shadow: 0 0 0 0 #6c8dc1, 0 0 0 1px rgb(125 117 193 / 50%), 0 0 #0000;
    }

    .read-more-gradient {
        background: linear-gradient(
            180deg,
            rgb(29 26 22 / 0%) 0%,
            rgb(29 26 22 / 100%) 100%
        );
    }

    .skeleton {
        --skeleton-0: #26221d;
        --skeleton-50: #443d35;
        --skeleton-100: #26221d;

        &.step {
            --skeleton-0: #3d372f;
            --skeleton-50: #565048;
            --skeleton-100: #3d372f;
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: #92887a;
    }

    .search-input {
        &::placeholder {
            color: #92887a;
        }
    }

    .input-invalid {
        &::placeholder {
            color: #c85130;
        }

        input::placeholder,
        textarea::placeholder {
            color: #c85130;
        }
    }

    select {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23736b60' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }

    input[type='checkbox'],
    input[type='radio'] {
        &:focus:not(:checked),
        &:focus-visible {
            @apply border-blue-300;

            box-shadow: 0 0 0 0 #6c8dc1, 0 0 0 3px rgb(125 117 193 / 50%),
                0 0 #0000;
        }
    }
}

@media (hover: none) and (pointer: coarse) {
    .mobile-scroll-box {
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

@media (max-width: 1024px) and (hover: none) and (pointer: coarse) {
    .mobile-horizontal-scroll {
        display: flex;
        overflow: auto hidden;
        flex-wrap: nowrap;
        padding: 0 0.125rem 1rem;
        margin-left: -0.125rem;

        > a,
        > div {
            width: 80vw;
            max-height: 85vw;
            flex: none;

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }
}

.first-letter-capitalize {
    display: inline-block;

    &::first-letter {
        text-transform: uppercase;
    }
}

/* stylelint-disable selector-class-pattern */
.StripeElement {
    @apply rounded-md border-grey-300 bg-white text-grey-800;
    @apply dark:border-grey-700 dark:bg-grey-900 dark:text-white;
    @apply h-[42px] w-full px-2 py-[0.65rem];
    @apply border-outline rounded-md border;
    @apply outline-none;
}

.area-selector-district,
.area-selector-town {
    /* for targeting only */
}
