.button {
    @apply relative h-5 w-6;

    transform: rotate(0deg);

    div {
        @apply absolute left-0 h-0.5 w-full rounded-sm bg-grey-300 opacity-100 dark:bg-grey-700;

        transform: rotate(0deg);

        &:nth-child(1) {
            @apply top-0;
        }

        &:nth-child(2) {
            @apply top-2;
        }

        &:nth-child(3) {
            @apply top-4;
        }
    }

    &.open {
        div {
            &:nth-child(1) {
                @apply top-2;

                transform: rotate(135deg);
            }

            &:nth-child(2) {
                @apply -left-8 opacity-0;
            }

            &:nth-child(3) {
                @apply top-2;

                transform: rotate(-135deg);
            }
        }
    }
}

.shadow {
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 10%));
}

.shadowUp {
    filter: drop-shadow(0 -4px 4px rgb(0 0 0 / 10%));
}

:global .dark {
    :local .shadow {
        filter: drop-shadow(0 0 4px rgb(0 0 0 / 20%));
    }

    :local .shadowUp {
        filter: drop-shadow(0 -4px 4px rgb(0 0 0 / 20%));
    }
}

@keyframes scale {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}
