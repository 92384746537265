.button {
    @apply relative h-[1.125rem] w-6;

    div {
        @apply absolute left-0 h-0.5 w-full rounded-sm bg-white opacity-100;

        &:nth-child(1) {
            @apply top-2;

            transform: rotate(135deg);
        }

        &:nth-child(2) {
            @apply top-2;

            transform: rotate(-135deg);
        }
    }
}

:global .dark {
    :local .button {
        div {
            @apply bg-white;
        }
    }
}
